import React from "react";
// import PropTypes from 'prop-types'

import styles from "./input.module.scss";

interface InputInterface {
  label?: null | string;
  labelHint?: null | string;
  hint?: string;
  type?: string;
  name?: string;
  value?: string | number;
  placeholder?: string;
  onChange?: Function | any;
  onKeyDown?: Function | any;
  maxLength?: number;
  className?: string;
  style?: object;
  size?: number;
  error?: boolean;
  errorMessage?: string;
  pattern?: string;
  autoCapitalize?: string;
  disabled?: boolean;
  required?: boolean;
  checked?: boolean;
  children?: any;
}

const InputGroup = ({
  label = null,
  labelHint = null,
  hint = "",
  type = "text",
  name = "",
  value,
  placeholder,
  onChange = () => {},
  onKeyDown = () => {},
  maxLength = 150,
  className = "",
  style = {},
  size,
  error = false,
  errorMessage = "",
  pattern = undefined,
  autoCapitalize = "off",
  disabled = false,
  required = false,
  checked,
  children,
  ...others
}: InputInterface) => (
  <div
    className={`${styles.root} ${className} ${error ? styles.inputError : ""}`}
    style={style}
  >
    {label && (
      <p className={styles.inputLabel}>
        {label}
        {labelHint && <b>({labelHint})</b>}
      </p>
    )}
    {hint && <p className={styles.inputHint}>{hint}</p>}

    {type === "select" ? (
      <select
        className={`${styles.formSelect} ${size}`}
        value={value}
        name={name}
        onChange={onChange}
        onKeyDown={onKeyDown}
      >
        {children}
      </select>
    ) : type === "checkbox" ? (
      <div
        id={name}
        className={`${
          checked ? styles.checkboxActive : styles.checkbox
        } ${size} ${required && styles.requiredCheckbox}`}
        onClick={onChange}
        onKeyDown={onKeyDown}
      >
        {children}
      </div>
    ) : type === "textarea" ? (
      <textarea
        className={styles.input}
        onChange={onChange}
        onKeyDown={onKeyDown}
        name={name}
        required={required}
        value={value}
        maxLength={maxLength}
      >
        {children}
      </textarea>
    ) : (
      <input
        value={value}
        type={type}
        name={name}
        onChange={onChange}
        onKeyDown={onKeyDown}
        placeholder={placeholder}
        maxLength={maxLength}
        className={`${type === "radio" ? styles.formRadio : styles.input} ${
          error ? "form-control is-invalid" : "form-control"
        }`}
        checked={checked}
        autoCapitalize={autoCapitalize}
        disabled={disabled}
        required={required}
        pattern={pattern}
        {...others}
      />
    )}
    {error && required && (
      <div className={styles.errorMessage}>{errorMessage}</div>
    )}
  </div>
);

// InputGroup.propTypes = {
//   type: PropTypes.string,
//   label: PropTypes.oneOfType([null, PropTypes.string]),
//   name: PropTypes.string,
//   value: PropTypes.string,
//   placeholder: PropTypes.string,
//   onChange: PropTypes.func,
//   onKeyDown: PropTypes.func,
//   maxLength: PropTypes.string,
//   className: PropTypes.string,
//   style: PropTypes.shape({}),
//   error: PropTypes.string,
//   disabled: PropTypes.bool,
// }

// InputGroup.defaultProps = {
//   type: 'text',
//   label: null,
//   name: '',
//   value: '',
//   placeholder: '',
//   onChange: () => {},
//   onKeyDown: () => {},
//   maxLength: '150',
//   className: '',
//   style: {},
//   error: '',
//   disabled: false,
// }

export default InputGroup;
